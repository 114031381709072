import { useState, useEffect } from 'react';
import axios from 'axios';
// Hooks
import usePegel from '../hooks/usePegel';

export default function DataLoader(props) {
  const { api, accesstoken, refreshtoken, project, onChangeData, onChangeAuth } = usePegel();
  const [intervalvalue, setIntervalvalue] = useState();

  useEffect(() => {
    if (accesstoken !== '') {
      axios
        .get(`${api}/keyvaluelist`, {
          headers: {
            Authorization: accesstoken,
            PID: project
          }
        })
        .then((responsekeyvaluelist) => {
          axios
            .get(`${api}/keyhistory`, {
              headers: {
                Authorization: accesstoken,
                PID: project
              }
            })
            .then((responsekeyhistory) => {
              axios
                .get(`${api}/server`, {
                  headers: {
                    Authorization: accesstoken,
                    PID: project
                  }
                })
                .then((responseserver) => {
                  onChangeData(
                    responsekeyvaluelist.data,
                    responsekeyhistory.data,
                    responseserver.data
                  );
                })
                .catch((error) => {
                  onChangeData(responsekeyvaluelist.data, responsekeyhistory.data, []);
                });
            })
            .catch((error) => {
              axios
                .get(`${api}/checktoken`, {
                  headers: {
                    Authorization: accesstoken
                  }
                })
                .then((response) => {})
                .catch((error) => {
                  axios
                    .get(`${api}/refreshtoken`, {
                      headers: {
                        refresh_token: refreshtoken
                      }
                    })
                    .then((response) => {
                      onChangeAuth(response.data.ACCESS_TOKEN, response.data.REFRESH_TOKEN);
                    })
                    .catch((error) => {
                      onChangeAuth('', '');
                    });
                });
            });
        })
        .catch((error) => {
          axios
            .get(`${api}/checktoken`, {
              headers: {
                Authorization: accesstoken
              }
            })
            .then((response) => {})
            .catch((error) => {
              axios
                .get(`${api}/refreshtoken`, {
                  headers: {
                    refresh_token: refreshtoken
                  }
                })
                .then((response) => {
                  onChangeAuth(response.data.ACCESS_TOKEN, response.data.REFRESH_TOKEN);
                })
                .catch((error) => {
                  onChangeAuth('', '');
                });
            });
        });
    }
  }, [accesstoken, project]);

  useEffect(() => {
    clearInterval(intervalvalue);
    if (accesstoken !== '') {
      setIntervalvalue(
        setInterval(() => {
          axios
            .get(`${api}/keyvaluelist`, {
              headers: {
                Authorization: accesstoken,
                PID: project
              }
            })
            .then((responsekeyvaluelist) => {
              axios
                .get(`${api}/keyhistory`, {
                  headers: {
                    Authorization: accesstoken,
                    PID: project
                  }
                })
                .then((responsekeyhistory) => {
                  axios
                    .get(`${api}/server`, {
                      headers: {
                        Authorization: accesstoken,
                        PID: project
                      }
                    })
                    .then((responseserver) => {
                      onChangeData(
                        responsekeyvaluelist.data,
                        responsekeyhistory.data,
                        responseserver.data
                      );
                    })
                    .catch((error) => {
                      onChangeData(responsekeyvaluelist.data, responsekeyhistory.data, []);
                    });
                })
                .catch((error) => {});
            })
            .catch((error) => {});
        }, 300000)
      );
    }
  }, [project, accesstoken]);

  return <></>;
}
