import { useEffect } from 'react';
import axios from 'axios';
import socketIOClient from 'socket.io-client';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import DataLoader from './components/DataLoader';
// Hooks
import usePegel from './hooks/usePegel';

// ----------------------------------------------------------------------

export default function App() {
  const {
    project,
    api,
    accesstoken,
    refreshtoken,
    onChangeAuth,
    onChangeKeyvalues,
    keyvalues,
    serveradministrator,
    onChangeUserinfo,
    onChangeSocket,
    socket
  } = usePegel();

  useEffect(() => {
    axios
      .get(`${api}/userinfo`, {
        headers: {
          Authorization: accesstoken
        }
      })
      .then((response) => {
        onChangeUserinfo(
          response.data.USERNAME,
          response.data.EMAIL,
          response.data.UID,
          response.data.PROJECTS,
          response.data.SERVER_ADMINISTRATOR
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [accesstoken]);

  // useEffect(() => {
  //   if (serveradministrator) {
  //     const socket = socketIOClient(api);
  //     socket.emit('project', {
  //       Authorization: accesstoken,
  //       PID: project
  //     });
  //     console.log('socket connected');
  //     onChangeSocket(socket);
  //   }
  //   return () => {
  //     if (socket) socket.disconnect();
  //   };
  // }, [serveradministrator, project, accesstoken]);

  return (
    <ThemeConfig>
      <ScrollToTop />
      <DataLoader />
      <Router />
    </ThemeConfig>
  );
}
