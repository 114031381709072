import { useEffect, useState } from 'react';
// material
import { Icon } from '@iconify/react';
import { Box, Grid, Container, Typography, Card, CardContent, Divider } from '@material-ui/core';
import RefreshIcon from '@iconify/icons-eva/refresh-fill';
import Chart from 'react-apexcharts';
import axios from 'axios';
// components
import Page from '../components/Page';
import KeyValueList from '../components/KeyValueList';
// Hooks
import usePegel from '../hooks/usePegel';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { historydata } = usePegel();
  const [seriesdata, setSeriesdata] = useState([]);

  useEffect(() => {
    const tempdata = [];
    for (let index = 0; index < historydata.length; index += 1) {
      const element = historydata[index];

      if (element.MAP_COUNT) {
        tempdata[tempdata.length] = {
          name: `${element.NAME} MAP_COUNT`,
          data: element.MAP_COUNT
        };
      }

      if (element.FILE_COUNT) {
        tempdata[tempdata.length] = {
          name: `${element.NAME} FILE_COUNT`,
          data: element.FILE_COUNT
        };
      }
    }
    setSeriesdata(tempdata);
  }, [historydata]);

  const options = {
    chart: {
      id: 'line'
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'datetime',
      tickAmount: 6
    }
  };

  const series = seriesdata;
  return (
    <Page title="Pegel-Dashboard | IT-Plan">
      <Container maxWidth="xl">
        <Box sx={{ pb: 4 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid item container spacing={3} sx={{ width: '100%' }}>
          <Grid item sx={{ width: '100%' }}>
            <Card>
              <CardContent>
                <KeyValueList />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Card>
              <CardContent>
                <Chart options={options} series={series} type="line" />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
