import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import socketIOClient from 'socket.io-client';
// material
import { Icon } from '@iconify/react';
import bxsServer from '@iconify/icons-bx/bxs-server';
import {
  Box,
  Grid,
  Container,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  CardHeader,
  Collapse,
  Slider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader
} from '@material-ui/core';
import Chart from 'react-apexcharts';
import axios from 'axios';
import ReactCountryFlag from 'react-country-flag';
// Hooks
import usePegel from '../hooks/usePegel';
// utils
import { fShortenNumber } from '../utils/formatNumber';

export default function ServerCard(props) {
  const { value, index } = props;
  const { servers, keyvalues, accesstoken, api, project, onChangeServers } = usePegel();
  const { id } = useParams();
  const [opencard, setOpencard] = useState(false);
  const [consolelog, setConsolelog] = useState([]);
  const [series, setSeries] = useState([]);
  const [intervalvalue, setIntervalvalue] = useState();
  const [sockdata, setSockdata] = useState({});
  const [socket, setSocket] = useState();

  const options = {
    chart: {
      id: 'line'
    },
    stroke: {
      curve: 'smooth'
    },
    yaxis: {
      min: 0
    },
    xaxis: {
      type: 'datetime'
    }
  };

  const handleStateChange = (state, sid) => {
    axios
      .patch(
        `${api}/serverstate/${id}`,
        {},
        {
          headers: {
            Authorization: accesstoken,
            PID: project,
            SERVER_STATE: state ? 0 : 1,
            SERVER_ID: sid
          }
        }
      )
      .then(() => {
        axios
          .get(`${api}/server`, {
            headers: {
              Authorization: accesstoken,
              PID: project
            }
          })
          .then((responseserver) => {
            onChangeServers(responseserver.data);
          })
          .catch((error) => {});
      });
  };

  useEffect(() => {
    const socket = socketIOClient(api);
    socket.emit('project', {
      Authorization: accesstoken,
      PID: project
    });
    console.log('socket connected');
    setSocket(socket);
    return () => {
      socket.close();
    };
  }, [project]);

  useEffect(() => {
    if (opencard) {
      axios
        .get(`${api}/consoleautomatedserver/${value.id}/${id}`, {
          headers: {
            Authorization: accesstoken,
            PID: project
          }
        })
        .then((response) => {
          setConsolelog(response.data.reverse());
        });
    }
  }, [opencard]);

  useEffect(() => {
    if (opencard) {
      axios
        .get(`${api}/metrics/${value.id}/${id}`, {
          headers: {
            Authorization: accesstoken,
            PID: project
          }
        })
        .then((response) => {
          const arr = [{ name: 'CPU', data: [] }];
          for (let i = 0; i < response.data.time_series.cpu.values.length; i += 1) {
            const element = response.data.time_series.cpu.values[i];
            arr[0].data.push({
              x: new Date(parseFloat(element[0]) * 1000).getTime(),
              y: parseInt(element[1], 10)
            });
          }
          setSeries(arr);
        });
      clearInterval(intervalvalue);
      setIntervalvalue(
        setInterval(() => {
          axios
            .get(`${api}/metrics/${value.id}/${id}`, {
              headers: {
                Authorization: accesstoken,
                PID: project
              }
            })
            .then((response) => {
              const arr = [{ name: 'CPU', data: [] }];
              for (let i = 0; i < response.data.time_series.cpu.values.length; i += 1) {
                const element = response.data.time_series.cpu.values[i];
                arr[0].data.push({
                  x: new Date(parseFloat(element[0]) * 1000).getTime(),
                  y: parseInt(element[1], 10)
                });
              }
              setSeries(arr);
            });
        }, 10000)
      );
    }
  }, [opencard]);

  useEffect(() => {
    if (opencard && socket) {
      console.log(`socket on ${value.id.toString()}`);
      socket.on(value.id.toString(), (data) => {
        console.log(data);
        setSockdata(data);
      });
    } else if (socket) {
      socket.off(value.id.toString());
    }
    return () => {
      if (socket) {
        console.log(`socket off ${value.id.toString()}`);
        socket.off(value.id.toString());
      }
    };
  }, [socket, opencard]);

  useEffect(() => {
    setConsolelog([sockdata, ...consolelog]);
  }, [sockdata]);

  return (
    <Grid item key={index} sx={{ width: '100%' }}>
      <Card
        sx={{
          backgroundColor: value.SERVER_STATE ? 'rgba(0, 255, 0, 0.1)' : 'rgba(255, 0, 0, 0.1)'
        }}
      >
        <CardContent>
          <Grid container direction="column" justifyContent="space-around" alignItems="center">
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              onClick={() => setOpencard(!opencard)}
            >
              <Grid item>
                <Icon
                  icon={bxsServer}
                  color={value.SERVER_STATE ? '#006600' : '#660000'}
                  width="40"
                  height="40"
                />
              </Grid>
              <Grid item xs={10}>
                <Grid container direction="row" justifyContent="space-around" alignItems="center">
                  <Grid item>
                    <Typography variant="p">
                      Name: <Typography variant="h6">{value.name}</Typography>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="p">
                      IP address:
                      <Typography variant="h6">{value.public_net.ipv4.ip}</Typography>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="p">
                      Location:
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <ReactCountryFlag countryCode={value.datacenter.location.country} svg />
                        </Grid>
                        <Grid item>
                          <Typography variant="h6">{value.datacenter.location.city}</Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="p">
                      Server Details:
                      <Typography variant="h6">
                        {value.server_type.cores} / {value.server_type.memory}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="p">
                      Start Time:
                      <Typography variant="h6">
                        {new Date(value.START_TIME).toLocaleTimeString()}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Switch
                  checked={value.SERVER_STATE}
                  onChange={() => handleStateChange(value.SERVER_STATE, value.id)}
                />
              </Grid>
            </Grid>
            <Collapse in={opencard} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
              <Grid item xs={12} sx={{ marginTop: 2, width: '100%' }}>
                <Card
                  sx={{
                    backgroundColor: value.SERVER_STATE
                      ? 'rgba(0, 255, 0, 0.1)'
                      : 'rgba(255, 0, 0, 0.1)'
                  }}
                >
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-around"
                      alignItems="center"
                    >
                      <Grid item xs={6}>
                        <Loglist consolelog={consolelog} />
                      </Grid>
                      <Grid item xs={6}>
                        {series && <Chart options={options} series={series} type="line" />}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Collapse>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

function Loglist(props) {
  const { consolelog } = props;
  return (
    <List
      sx={{
        bgcolor: 'white',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
        '& ul': { padding: 0 }
      }}
      subheader={<li />}
      dense
    >
      <ListSubheader component="div" id="nested-list-subheader">
        Console
      </ListSubheader>
      {consolelog.map((value, index) => (
        <div key={index}>
          <Divider />
          <ListItem key={index}>
            <ListItemText>
              {`${new Date(Date.parse(value.LOG_TIME)).toLocaleString()}:
              ${value.CONSOLE_OUT}`}
            </ListItemText>
          </ListItem>
        </div>
      ))}
    </List>
  );
}
