import { useState, useEffect } from 'react';
import axios from 'axios';
// material
import { Icon } from '@iconify/react';
import {
  Box,
  Grid,
  TextField,
  IconButton,
  Tabs,
  Tab,
  Card,
  CardContent,
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  Switch,
  Typography
} from '@material-ui/core';
import RefreshIcon from '@iconify/icons-eva/refresh-outline';
import PlusIcon from '@iconify/icons-eva/plus-outline';
import TrashIcon from '@iconify/icons-eva/trash-2-outline';
import EditIcon from '@iconify/icons-eva/edit-2-outline';
// Hooks
import usePegel from '../hooks/usePegel';
import KeyValueBox from './KeyValueBox';
import Countdown from './Countdown';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export default function KeyValueList(props) {
  const {
    keyvalues,
    api,
    accesstoken,
    project,
    onChangeKeyvalues,
    dashboards,
    onChangeDashboards,
    serveradministrator
  } = usePegel();

  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [keyValues, setKeyValues] = useState([]);
  const [title, setTitle] = useState([]);
  const [automationstate, setAutomationstate] = useState(false);
  const [automationinterval, setAutomationinterval] = useState();

  /* eslint no-unneeded-ternary: "off", curly: "error" */
  useEffect(() => {
    clearInterval(automationinterval);
    axios
      .get(`${api}/automationstatus`, {
        headers: {
          Authorization: accesstoken,
          PID: project
        }
      })
      .then((response) => {
        setAutomationstate(response.data.AUTOMATION === 1 ? true : false);
      })
      .catch((error) => {
        console.log(error);
      });
    setAutomationinterval(
      setInterval(() => {
        axios
          .get(`${api}/automationstatus`, {
            headers: {
              Authorization: accesstoken,
              PID: project
            }
          })
          .then((response) => {
            setAutomationstate(response.data.AUTOMATION === 1 ? true : false);
          })
          .catch((error) => {
            console.log(error);
          });
      }, 10000)
    );
    return () => {
      clearInterval(automationinterval);
    };
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickEdit = () => {
    setTitle(dashboards[value].title);
    setKeyValues(dashboards[value].keyvalues);
    setOpen(true);
  };

  const handleClose = () => {
    setTitle('');
    setKeyValues([]);
    setOpen(false);
  };

  const onReloadClick = () => {
    axios
      .get(`${api}/keyvaluelist`, {
        headers: {
          Authorization: accesstoken,
          PID: project
        }
      })
      .then((response) => {
        onChangeKeyvalues(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCreateDashboard = () => {
    setOpen(false);
    const newDashboards = dashboards;
    if (newDashboards.map((e) => e.title).indexOf(title) > -1) {
      newDashboards[newDashboards.map((e) => e.title).indexOf(title)] = {
        title,
        keyvalues: keyValues
      };
    } else {
      newDashboards.push({ title, keyvalues: keyValues });
    }
    setKeyValues([]);
    setTitle('');
    onChangeDashboards(newDashboards);
  };

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setKeyValues(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const dropDashboard = (index) => {
    if (index !== 0) {
      const newdashboards = dashboards;
      newdashboards.splice(index, 1);
      setValue(value - 1);
      onChangeDashboards(newdashboards);
    }
  };

  const handleAutomationChange = (event) => {
    axios
      .patch(
        `${api}/automationstatus/${event.target.checked ? 1 : 0}`,
        {},
        {
          headers: {
            Authorization: accesstoken,
            PID: project
          }
        }
      )
      .then((response) => {
        setAutomationstate(response.data.AUTOMATION);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  try {
    return (
      <>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <Tabs
                value={value}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {dashboards.map((value, index) => (
                  <Tab label={value.title} key={index} />
                ))}
              </Tabs>
              <IconButton color="primary" onClick={() => handleClickOpen()}>
                <Box component={Icon} icon={PlusIcon} />
              </IconButton>
            </Grid>
          </Grid>
          {serveradministrator && (
            <Grid item>
              <Countdown timestamp={0} />
              <Typography variant="p">Automation</Typography>
              <Switch
                checked={typeof automationstate === 'boolean' && automationstate}
                onChange={handleAutomationChange}
              />
            </Grid>
          )}
          <Grid item>
            <IconButton color="warning" onClick={() => handleClickEdit()} sx={{ boxShadow: 3 }}>
              <Box component={Icon} icon={EditIcon} />
            </IconButton>
            <IconButton
              color="error"
              onClick={() => dropDashboard(value)}
              sx={{ boxShadow: 3, marginLeft: 1 }}
            >
              <Box component={Icon} icon={TrashIcon} />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => onReloadClick()}
              sx={{ boxShadow: 3, marginLeft: 1 }}
            >
              <Box component={Icon} icon={RefreshIcon} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={3} pt={1}>
          {dashboards[value].title === 'All'
            ? keyvalues.map((item, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <KeyValueBox title={item.NAME} data={item} i={index} />
                </Grid>
              ))
            : keyvalues.map(
                (item, index) =>
                  dashboards[value].keyvalues.indexOf(item.NAME) !== -1 && (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                      <KeyValueBox title={item.NAME} data={item} i={index} />
                    </Grid>
                  )
              )}
        </Grid>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Create new Dashboard</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              autoComplete="off"
              margin="dense"
              id="title"
              label="Dashboard Title"
              type="text"
              fullWidth
              variant="standard"
              value={title}
              onInput={(e) => setTitle(e.target.value)}
            />
            <div>
              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-checkbox-label">Keyvalues</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={keyValues}
                  onChange={handleChange}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {keyvalues.map((value) => (
                    <MenuItem key={value.NAME} value={value.NAME}>
                      <Checkbox checked={keyValues.indexOf(value.NAME) > -1} />
                      <ListItemText primary={value.NAME} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={() => handleCreateDashboard()}>Create</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  } catch (e) {
    return <></>;
  }
}
