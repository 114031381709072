import { useNavigate } from 'react-router-dom';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Card, Typography, Divider } from '@material-ui/core';
// utils
import { fShortenNumber } from '../utils/formatNumber';
// Hooks
import usePegel from '../hooks/usePegel';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const TOTAL = 187;

export default function KeyValueBox(props) {
  /* eslint no-nested-ternary: 0 */
  /* eslint no-else-return: 0 */
  const navigate = useNavigate();
  const { title, data, i } = props;
  const { servers } = usePegel();

  let RootStyle;

  if (i % 4 === 0) {
    RootStyle = styled(Card)(({ theme }) => ({
      boxShadow: theme.shadows[2],
      textAlign: 'center',
      padding: theme.spacing(1, 0),
      color: theme.palette.success.darker,
      backgroundColor: theme.palette.success.lighter
    }));
  } else if (i % 3 === 0) {
    RootStyle = styled(Card)(({ theme }) => ({
      boxShadow: theme.shadows[2],
      textAlign: 'center',
      padding: theme.spacing(1, 0),
      color: theme.palette.error.darker,
      backgroundColor: theme.palette.error.lighter
    }));
  } else if (i % 2 === 0) {
    RootStyle = styled(Card)(({ theme }) => ({
      boxShadow: theme.shadows[2],
      textAlign: 'center',
      padding: theme.spacing(1, 0),
      color: theme.palette.info.darker,
      backgroundColor: theme.palette.info.lighter
    }));
  } else {
    RootStyle = styled(Card)(({ theme }) => ({
      boxShadow: theme.shadows[2],
      textAlign: 'center',
      padding: theme.spacing(1, 0),
      color: theme.palette.warning.darker,
      backgroundColor: theme.palette.warning.lighter
    }));
  }

  return (
    <RootStyle
      onClick={() =>
        data.SERVER_NAME &&
        servers.length > 0 &&
        navigate(`/dashboard/keyvalue/${data.KID}`, { replace: true })
      }
    >
      <Typography variant="h6">{title}</Typography>
      <Divider />
      {Object.entries(data).map((value, index) =>
        value[0] !== 'NAME' &&
        value[0] !== 'KID' &&
        value[0] !== 'SERVER_NAME' &&
        value[0] !== 'MAX_SERVER_COUNT' ? (
          <div key={index}>
            <Typography variant="subtitle1" sx={{ opacity: 0.72 }}>
              {value[0]}
            </Typography>
            <Typography variant="h3">{fShortenNumber(value[1])}</Typography>
          </div>
        ) : value[0] === 'SERVER_NAME' && value[1] !== null && servers.length > 0 ? (
          <div key={index}>
            <Typography variant="subtitle1" sx={{ opacity: 0.72 }}>
              Server currently running
            </Typography>
            <Typography variant="h3">
              {servers.length > 0 &&
                servers.find((element) => element.name === value[1]).servers.length}
            </Typography>
          </div>
        ) : (
          <div key={index} />
        )
      )}
    </RootStyle>
  );
}

function countInArray(array, what) {
  let count = 0;
  for (let i = 0; i < array.length; i += 1) {
    if (array[i].name.startsWith(what)) {
      count += 1;
    }
  }
  return count;
}
