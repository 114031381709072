import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
// material
import { alpha } from '@material-ui/core/styles';
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
  ListItemIcon,
  ListItemText,
  List,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FolderIcon from '@material-ui/icons/Folder';
import ReportIcon from '@material-ui/icons/Report';
// components
import MenuPopover from '../../components/MenuPopover';
//
import account from '../../_mocks_/account';
// Hooks
import usePegel from '../../hooks/usePegel';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: '/'
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [projectopen, setProjectopen] = useState(false);
  const { api, onChangeAuth, username, email, onChangeProject, project, projects, accesstoken } =
    usePegel();
  const [createReportOpen, setCreateReportOpen] = useState(false);
  const [reportemail, setReportEmail] = useState(email);
  const [reporttext, setReportText] = useState('');
  const [reporterrortext, setReportErrorText] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    onChangeAuth('', '');
  };

  const handleClick = () => {
    setProjectopen(!projectopen);
  };

  const handleProjectSwitch = (data) => {
    if (project !== data) onChangeProject(data);
  };

  const handleCloseDialog = () => {
    setCreateReportOpen(false);
  };

  const handleClickReport = () => {
    setCreateReportOpen(true);
  };

  const handleSendReport = () => {
    axios
      .post(
        `${api}/support`,
        {
          EMAIL: reportemail,
          TEXT: reporttext
        },
        {
          headers: {
            Authorization: accesstoken,
            PID: project
          }
        }
      )
      .then((r) => {
        setCreateReportOpen(false);
      })
      .catch((e) => {
        setReportErrorText('An error occured while sending your Report!');
      });
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {username}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <MenuItem
          key="Test"
          onClick={handleClickReport}
          sx={{ typography: 'body2', py: 1, px: 2, backgroundColor: 'red' }}
        >
          <ListItemIcon>
            <ReportIcon color="warning" />
          </ListItemIcon>
          <ListItemText primary="Support Report" />
        </MenuItem>
        <MenuItem key="Test2" onClick={handleClick} sx={{ typography: 'body2', py: 1, px: 2.5 }}>
          <ListItemIcon>
            <FolderIcon />
          </ListItemIcon>
          <ListItemText primary="Projects" />
          {projectopen ? <ExpandLess /> : <ExpandMore />}
        </MenuItem>
        <Collapse in={projectopen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {projects &&
              projects.map((data) => (
                <MenuItem
                  value={data}
                  key={data}
                  onClick={() => handleProjectSwitch(data)}
                  sx={{
                    typography: 'body2',
                    py: 1,
                    px: 2.5,
                    bgcolor: project === data ? 'Highlight' : ''
                  }}
                >
                  <ListItemIcon />
                  <ListItemText primary={data} />
                </MenuItem>
              ))}
          </List>
        </Collapse>
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={() => handleLogout()}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
      <Dialog open={createReportOpen} onClose={handleCloseDialog}>
        <DialogTitle>Send a Support Report message!</DialogTitle>
        <DialogContent id="alert-dialog-title">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item>Please fill in the following information:</Grid>
            <Grid item>
              <TextField
                label="Email"
                variant="outlined"
                defaultValue={email}
                value={reportemail}
                onChange={(event) => setReportEmail(event.target.value)}
                required
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                label="Message"
                variant="outlined"
                value={reporttext}
                onChange={(event) => setReportText(event.target.value)}
                minRows={4}
                multiline
                fullWidth
              />
            </Grid>
            <Grid item>{reporterrortext}</Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="error">
            Cancle
          </Button>
          <Button onClick={handleSendReport}>Send</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
