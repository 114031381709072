import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
// material
import { Icon } from '@iconify/react';
import bxsServer from '@iconify/icons-bx/bxs-server';
import {
  Box,
  Grid,
  Container,
  Typography,
  Card,
  CardContent,
  Slider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch
} from '@material-ui/core';
import Chart from 'react-apexcharts';
import axios from 'axios';
import ReactCountryFlag from 'react-country-flag';
// components
import Page from '../components/Page';
import KeyValueList from '../components/KeyValueList';
import DataLoader from '../components/DataLoader';
import ServerCard from '../components/ServerCard';
// Hooks
import usePegel from '../hooks/usePegel';
// utils
import { fShortenNumber } from '../utils/formatNumber';

// ----------------------------------------------------------------------

export default function DashboardKeyvalue() {
  const { servers, keyvalues, accesstoken, api, project, onChangeServers } = usePegel();
  const { id } = useParams();
  const [keyvalue, setKeyvalue] = useState();
  const [server, setServer] = useState();
  const [slidercount, setSlidercount] = useState(1);
  const [createAlertOpen, setCreateAlertOpen] = useState(false);
  const [monthlyprice, setMonthlyPrice] = useState(0);
  const [stype, setStype] = useState('');
  const [automated, setAutomated] = useState(false);

  useEffect(() => {
    setKeyvalue(keyvalues.find((element) => element.KID === parseInt(id, 10)));
  }, [keyvalues]);

  useEffect(() => {
    setServer(keyvalue && servers.find((element) => element.name === keyvalue.SERVER_NAME));
  }, [keyvalue, servers, project]);

  useEffect(() => {
    if (server) {
      let mprice = 0;
      server.servers.map(
        (value) => (mprice += parseFloat(value.server_type.prices[0].price_monthly.net, 10))
      );
      setMonthlyPrice(mprice);
    }
  }, [server]);

  const handleClickOpen = (value) => {
    setStype(value);
    setCreateAlertOpen(true);
  };

  const handleClose = () => {
    setCreateAlertOpen(false);
  };

  const createServer = (index) => {
    axios
      .post(
        `${api}/server/${id}`,
        {},
        {
          headers: {
            Authorization: accesstoken,
            PID: project,
            SERVER_TYPE: stype !== '' ? stype : null,
            AUTOGENERATED: automated
          }
        }
      )
      .then(() => {
        if (index === slidercount - 1) {
          console.log('Kill');
          axios
            .get(`${api}/server`, {
              headers: {
                Authorization: accesstoken,
                PID: project
              }
            })
            .then((responseserver) => {
              onChangeServers(responseserver.data);
            })
            .catch((error) => {});
        }
      });
    if (index + 1 < slidercount)
      setTimeout(() => {
        createServer(index + 1);
      }, 30000);
  };

  const handleCreate = () => {
    setCreateAlertOpen(false);
    createServer(0);
  };

  const handleStateChange = (state, sid) => {
    axios
      .patch(
        `${api}/serverstate/${id}`,
        {},
        {
          headers: {
            Authorization: accesstoken,
            PID: project,
            SERVER_STATE: state ? 0 : 1,
            SERVER_ID: sid
          }
        }
      )
      .then(() => {
        axios
          .get(`${api}/server`, {
            headers: {
              Authorization: accesstoken,
              PID: project
            }
          })
          .then((responseserver) => {
            onChangeServers(responseserver.data);
          })
          .catch((error) => {});
      });
  };

  return (
    <Page title="Pegel-Dashboard | IT-Plan">
      <Container maxWidth="xl">
        <Box sx={{ pb: 4 }}>
          <Typography variant="h4">{keyvalue && keyvalue.SERVER_NAME} servers</Typography>
        </Box>
        <Grid item container spacing={3} sx={{ width: '100%' }}>
          <Grid item>
            <Card sx={{ backgroundColor: 'lightgreen' }}>
              <CardContent>
                <Typography variant="p">
                  Total Servers:
                  <Typography variant="h6">{server && server.servers.length}</Typography>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card sx={{ backgroundColor: 'lightgreen' }}>
              <CardContent>
                <Typography variant="p">
                  Total Cost per Month:
                  <Typography variant="h6">{fShortenNumber(monthlyprice)} €</Typography>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card sx={{ backgroundColor: 'lightgreen' }}>
              <CardContent>
                <Typography variant="p">
                  Maps Count:
                  <Typography variant="h6">
                    {keyvalues.find((element) => element.KID === parseInt(id, 10)) &&
                      fShortenNumber(
                        keyvalues.find((element) => element.KID === parseInt(id, 10))['Mapps Count']
                      )}
                  </Typography>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card sx={{ backgroundColor: 'lightgreen' }}>
              <CardContent>
                <Typography variant="p">
                  File Count:
                  <Typography variant="h6">
                    {keyvalues.find((element) => element.KID === parseInt(id, 10)) &&
                      fShortenNumber(
                        keyvalues.find((element) => element.KID === parseInt(id, 10))['File Count']
                      )}
                  </Typography>
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* <Grid item sx={{ width: '100%' }}>
            <Card>
              <CardContent>
                <Grid container direction="row" justifyContent="space-around" alignItems="center">
                  <Grid item>
                    <Icon icon={bxsServer} width="16" height="16" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid> */}
          {server &&
            server.servers.map((value, index) => <ServerCard value={value} index={index} />)}
          <Grid item sx={{ width: '100%' }}>
            <Card sx={{ backgroundColor: 'lightgreen' }}>
              <CardContent>
                <Grid container direction="row" justifyContent="space-around" alignItems="center">
                  <Grid item xs={2}>
                    <Button variant="contained" onClick={() => handleClickOpen('cpx51')}>
                      <Typography variant="h6">Create 16 Core</Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={2}>
                    {keyvalue && keyvalue.KID === 1 ? (
                      <Button variant="contained" onClick={() => handleClickOpen('cx11')}>
                        <Typography variant="h6">Create 1 Core</Typography>
                      </Button>
                    ) : (
                      <Button variant="contained" onClick={() => handleClickOpen('cx51')}>
                        <Typography variant="h6">Create 8 Core</Typography>
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Slider
                      defaultValue={1}
                      valueLabelDisplay="on"
                      step={1}
                      marks
                      min={1}
                      max={10}
                      value={slidercount}
                      onChange={(e, value) => setSlidercount(value)}
                      sx={{ position: 'relative', zIndex: 7 }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="p">Automated</Typography>
                    <Switch checked={automated} onChange={() => setAutomated(!automated)} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Dialog open={createAlertOpen} onClose={handleClose}>
        <DialogContent id="alert-dialog-title">
          <p>
            You're about to create <b>{slidercount}</b> Servers from Type
            <b> {keyvalue && keyvalue.SERVER_NAME}</b>!
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Cancle
          </Button>
          <Button onClick={handleCreate} autoFocus>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}
