import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';
// hooks
import useLocalStorage from '../hooks/useLocalStorage';

// ----------------------------------------------------------------------

const initialState = {
  accesstoken: '',
  refreshtoken: '',
  project: 0,
  projects: [],
  username: '',
  email: '',
  serveradministrator: false,
  dashboards: [],
  moderators: [],
  vips: [],
  api: '',
  keyvalues: [],
  servers: [],
  RESTART_TIME: 0,
  historydata: [],
  socket: null,
  onChangeModerators: () => {},
  onChangeVips: () => {},
  onChangeApi: () => {},
  onChangeKeyvalues: () => {},
  onChangeServers: () => {},
  onChangeSocket: () => {},
  onChangeRoles: () => {},
  onChangeUserinfo: () => {}
};

const PegelContext = createContext(initialState);

PegelProvider.propTypes = {
  children: PropTypes.node
};

function PegelProvider({ children }) {
  const [pegellocal, setPegellocal] = useLocalStorage('pegel', {
    accesstoken: '',
    refreshtoken: '',
    project: 0,
    projects: [],
    username: '',
    email: '',
    serveradministrator: false,
    dashboards: [
      {
        title: 'All',
        keyvalues: []
      }
    ]
  });

  const [pegelstate, setPegelstate] = useState({
    moderators: [],
    vips: [],
    keyvalues: [],
    historydata: [],
    servers: [],
    RESTART_TIME: 0,
    socket: null,
    api: 'https://pegel.it-plan.de'
    // api: 'http://localhost:443'
  });

  const onChangeAccessToken = (val) => {
    setPegellocal({
      ...pegellocal,
      accesstoken: val
    });
  };

  const onChangeRefreshToken = (val) => {
    setPegellocal({
      ...pegellocal,
      refreshtoken: val
    });
  };

  const onChangeProject = (val) => {
    setPegellocal({
      ...pegellocal,
      project: val
    });
  };

  const onChangeProjects = (val) => {
    setPegellocal({
      ...pegellocal,
      projects: val
    });
  };

  const onChangeUsername = (val) => {
    setPegellocal({
      ...pegellocal,
      username: val
    });
  };

  const onChangeEmail = (val) => {
    setPegellocal({
      ...pegellocal,
      email: val
    });
  };

  const onChangeDashboards = (val) => {
    setPegellocal({
      ...pegellocal,
      dashboards: val
    });
  };

  const onChangeModerators = (val) => {
    setPegelstate({
      ...pegelstate,
      moderators: val
    });
  };

  const onChangeVips = (val) => {
    setPegelstate({
      ...pegelstate,
      vips: val
    });
  };

  const onChangeKeyvalues = (val) => {
    val.forEach((value, index) => {
      if (value.SERVER_NAME === '') {
        val[index].SERVER_NAME = null;
      }
    });
    setPegelstate({
      ...pegelstate,
      keyvalues: val
    });
  };

  const onChangeApi = (val) => {
    setPegelstate({
      ...pegelstate,
      api: val
    });
  };

  const onChangeServers = (val) => {
    setPegelstate({
      ...pegelstate,
      servers: val
    });
  };

  const onChangeSocket = (val) => {
    setPegelstate({
      ...pegelstate,
      socket: val
    });
  };

  const onChangeRoles = (mod, vip) => {
    setPegelstate({
      ...pegelstate,
      moderators: mod,
      vips: vip
    });
  };

  const onChangeAuth = (accesstoken, refreshtoken) => {
    setPegellocal({
      ...pegellocal,
      accesstoken,
      refreshtoken
    });
  };

  const onChangeUserinfo = (username, email, uid, projects, serveradministrator) => {
    setPegellocal({
      ...pegellocal,
      username,
      email,
      uid,
      projects,
      serveradministrator
    });
  };

  const onChangeData = (keyvalues, historydata, servers) => {
    keyvalues.forEach((value, index) => {
      if (value.SERVER_NAME === '') {
        keyvalues[index].SERVER_NAME = null;
      }
    });

    setPegelstate({
      ...pegelstate,
      keyvalues,
      historydata,
      servers
    });
  };

  return (
    <PegelContext.Provider
      value={{
        ...pegellocal,
        ...pegelstate,
        // Access Token
        onChangeAccessToken,
        // Refresh Token
        onChangeRefreshToken,
        // Project
        onChangeProject,
        // Projects
        onChangeProjects,
        // Username
        onChangeUsername,
        // Email
        onChangeEmail,
        // Dashboards
        onChangeDashboards,
        // Moderators
        onChangeModerators,
        // Vips
        onChangeVips,
        // Key Values
        onChangeKeyvalues,
        // Servers
        onChangeServers,
        // Socket
        onChangeSocket,
        // Api
        onChangeApi,
        // Roles
        onChangeRoles,
        // Auth
        onChangeAuth,
        // userinfo
        onChangeUserinfo,
        // Data
        onChangeData
      }}
    >
      {children}
    </PegelContext.Provider>
  );
}

export { PegelProvider, PegelContext };
