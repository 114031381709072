import React, { useEffect, useState } from 'react';
import socketIOClient from 'socket.io-client';
// Hooks
import usePegel from '../hooks/usePegel';

export default function Countdown(props) {
  const { api, accesstoken, project } = usePegel();
  const [time, setTime] = useState();
  const [intervalTimer, setIntervalTimer] = useState();
  const [timestamp, setTimestamp] = useState(props.timestamp);
  const [socket, setSocket] = useState();

  useEffect(() => {
    const socket = socketIOClient(api);
    socket.emit('project', {
      Authorization: accesstoken,
      PID: project
    });
    setSocket(socket);
    return () => {
      socket.close();
    };
  }, [project]);

  useEffect(() => {
    if (socket)
      socket.on('RESTART_TIME', (data) => {
        setTimestamp(new Date(data).getTime() / 1000);
      });
  }, [socket]);

  useEffect(() => {
    clearInterval(intervalTimer);
    setIntervalTimer(
      setInterval(() => {
        // Give me the HH:MM:SS diffrence bewteen now and the timestamp
        let diff;
        if (timestamp) {
          if (timestamp < new Date().getTime() / 1000) {
            diff = new Date() - new Date(timestamp * 1000);
          } else {
            diff = new Date(timestamp * 1000) - new Date();
          }

          const diffHours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const diffMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
          const diffSeconds = Math.floor((diff % (1000 * 60)) / 1000);
          setTime(`${diffHours}h ${diffMinutes}m ${diffSeconds}s`);
        } else {
          setTime();
        }
      }, 1000)
    );
  }, [timestamp]);

  useEffect(() => {
    /* eslint arrow-body-style: ["error", "always"] */
    return () => {
      clearInterval(intervalTimer);
    };
  }, [intervalTimer]);

  return <div>{time}</div>;
}
