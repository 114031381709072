import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import DashboardApp from './pages/DashboardApp';
import DashboardKeyvalue from './pages/DashboardKeyvalue';
import NotFound from './pages/Page404';
// Hooks
import usePegel from './hooks/usePegel';

// ----------------------------------------------------------------------

export default function Router() {
  /* eslint no-nested-ternary: 0 */
  const { accesstoken, api, serveradministrator } = usePegel();

  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        {
          path: '/',
          element:
            accesstoken !== '' ? (
              <Navigate to="/dashboard/app" replace />
            ) : (
              <Navigate to="/login" replace />
            )
        },
        {
          path: 'app',
          element: accesstoken !== '' ? <DashboardApp /> : <Navigate to="/login" replace />
        },
        {
          path: 'keyvalue/:id',
          element:
            accesstoken !== '' ? (
              serveradministrator ? (
                <DashboardKeyvalue />
              ) : (
                <Navigate to="/dashboard/app" replace />
              )
            ) : (
              <Navigate to="/login" replace />
            )
        }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        {
          path: '/login',
          element: accesstoken !== '' ? <Navigate to="/dashboard/app" replace /> : <Login />
        },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
